import React from "react";
import CentralBox from "../../components/central_box/CentralBox";
import { useHistory, useLocation } from "react-router-dom";
import { ClientProcess } from "../../types/ClientProcess";
import Swal from "sweetalert2";

const Client = () => {
  const history = useHistory();
  const location = useLocation();

  const form = [["Proceso", "suitcase", "text"]];
  const buttons = ["Enviar"];

  const ClientSubmit = async (clientProcess: ClientProcess) => {

    if(!clientProcess.Proceso || clientProcess.Proceso === 0 ){

      Swal.fire({
        text: "Por favor selecciona un proceso antes de continuar",
        icon: "warning",
        timer: 3000,
        showConfirmButton: false,
      });
      return;
    }

    const process = location.state.filter(
      (object: any) => object.process === clientProcess.Proceso
    );
    const clientInfo = { customer: 2, process: process[0].id_process };
    history.push("/List", clientInfo);
  };


  return (
    <>
      <CentralBox
        title="Tu Asistente Personalizado"
        phrase="Selecciona el proceso al que quieres acceder"
        height="55%"
        buttons={buttons}
        infoForm={form}
        onSubmit={ClientSubmit}
        optionValues={location.state}
      />
    </>
  );
};

export default Client;
